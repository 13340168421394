import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    font-Family: 'Droid Serif', Serif;
    font-Size: 15px;
`

interface IFooter {}

const Footer:FunctionComponent<IFooter> = ()=> {
    return (
        <StyledFooter>
            <p>&copy; 2020, Tommy Chanthaboune</p>
        </StyledFooter>
    )
}

export default Footer;