import React from 'react';
import { Helmet } from 'react-helmet';

import Post from './Post';
import Header from './Header';
import Footer from './Footer';

const App = () => {
  return (
    <>
      <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Droid+Serif|Roboto:300" rel="stylesheet"/>
          <meta property="og:image" content="/images/07-10-2020-a-prince.jpg" />
      </Helmet>

      <div className="app">
        <Header />
        <Post title='a prince' post='07-10-2020-a-prince' />
        <Post title='a little seasoning' post='07-05-2020-seasons' />
        <Footer />
      </div>
    </>
  );
}

export default App;
