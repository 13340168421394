import React, { FunctionComponent } from 'react'
import styled from 'styled-components';

interface IHeader {}

const PrimaryHeader = styled.header`
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
`;

const LogoContainer = styled.div`
    width: 150px;
`;

const Logo = styled.img`
    width: 100%;
`

const Header: FunctionComponent<IHeader> = ()=>{

    return (
        <PrimaryHeader>
            <LogoContainer>
                <Logo src='/images/logo.jpg' alt='Hey There Marcus'/>
            </LogoContainer>
        </PrimaryHeader>
    )

}

export default Header