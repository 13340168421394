import React, {FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown/with-html';

interface IPost {
    post: string,
    title: string,
}

const Header = styled.h1`
    font-Family: 'Roboto', Sans-Serif;
    font-Size: 35px;
    text-transform: uppercase;
`

const PostContainer = styled.section`
    padding: 2rem 1rem;

    &::after {
        content: '';
        height: 8rem;
        width: 100%;
        background-image: url('/images/logo.jpg');
        background-repeat: no-repeat;
        display: block;
        background-size: 130px;
        background-position: center;
    }

    @media screen and (min-width: 768px) {
        padding: 3rem 25%;
    }

`;

const Content = styled.div`
    font-Family: 'Droid Serif', Serif;
    font-Size: 15px;
    line-height: 2.50;
    padding-bottom: 2rem;
`

const ContentImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
const ContentImageContainer = styled.div`
    box-shadow: 5px 5px 10px 0 rgba(0,0,0,.25);
    padding: .25rem .5rem;
    max-height: 400px;
    min-height: 400px;
`

const Image = styled.img`
    width: 100%;
`

const Post: FunctionComponent<IPost> = ({title, post}) => {

    const [content, setContent] = useState('');

    fetch(`/content/${post}.md`).then(res => res.text()).then(text => setContent(text));

    return (
        <PostContainer>
            <Header>
                {title}
            </Header>
            <Content>
                <ReactMarkdown source={content} />
            </Content>
            <ContentImage>
                <ContentImageContainer>
                    <Image src={`/images/${post}.jpg`} alt={`${post}`}/>
                </ContentImageContainer>
            </ContentImage>
        </PostContainer>
    )
}

export default Post;